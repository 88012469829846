/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Contains paragraphs styles.
 */
.cw-paragraph__title h2 {
  word-wrap: break-word;
}

.cw-paragrap__view-all {
  margin-top: map-get($spacers, 3);
}

.cw-text-with-side-image:not(.paragraph-width--narrow) {
  @include media-breakpoint-up(lg) {
    .paragraph--body.order-1,
    .paragraph--media.order-1 {
      padding-right: $cw-text-with-side-image-paragraph-body-padding;
    }

    .paragraph--body.order-2,
    .paragraph--media.order-2 {
      padding-left: $cw-text-with-side-image-paragraph-body-padding;
    }
  }
}

.cw-main-news-listing,
.cw-main-opportunities-listing,
.cw-main-resource-listing,
.cw-featured-events,
.cw-featured-past-events,
.cw-collections-cards,
.cw-personalised-cards {
  .view-content {
    --bs-gutter-y: #{map-get($spacers, 2)};
  }
}

.card--type-news,
.card--type-resource,
.card--type-opportunity,
.card--type-team {
  border: 0;

  h5 {
    @include cw-text-truncate(4);
  }
}

.webform-form__cw-newsletter {
  .form-item {
    margin-top: 0;
  }
}

.cw-product-description {
  padding-left: map-get($spacers, 2);
  padding-right: map-get($spacers, 1);
  padding-top: map-get($spacers, 2);

  h4.card-title {
    font-size: $h4-font-size;
  }

  .card-text > h4 {
    color: $dark;
    font-size: $h4-font-size;
  }
}

.cw-faq-listing {
  padding: map-get($spacers, 2) 0;

  .cw-paragraph__title {
    border-bottom: 1px solid $black;
    margin-bottom: 0 !important;

    h2 {
      font-size: $h5-font-size;
    }
  }
}

.cw-main-event-listing,
.cw-main-opportunities-listing {
  .view-display-id-list_view {
    .form-item-field-geofield-distance-distance,
    .form-item-field-geofield-distance-unit,
    .form-item-field-geofield-distance-origin {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }

    .section-list-view {
      @include media-breakpoint-up(lg) {
        max-height: $cw-map-view-attachment-height;
      }
      overflow: auto;

      &::-webkit-scrollbar {
        width: $cw-scrollbar-width;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $gray-500;
        border-radius: $cw-scrollbar-width;
      }
    }
  }
}
