/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Carousel styles overrides.
 */

.cw-carousel-indicators-right,
.cw-carousel-indicators-left {
  button {
    background-clip: padding-box;
    background-color: $white;
    border: 0;
    border-bottom: $cw-carousel-indicators-button-border;
    border-top: $cw-carousel-indicators-button-border;
    cursor: pointer;
    opacity: $btn-close-opacity;
    width: $cw-carousel-indicators-width !important;
  }

  button.active {
    opacity: $btn-close-focus-opacity;
  }
}

.content-section-carousel {
  min-height: $cw-carousel-image-height !important;
  position: relative !important;
}

.cw-carousel-subtext {
  min-height: $cw-carousel-subtext-height !important;
}

@include media-breakpoint-down(lg) {
  .paragraph-width--full-bleed,
  .paragraph-width--full,
  .paragraph-width--wide {
    .cw-image-left {
      display: flex;
      flex-direction: column-reverse;
    }

    .carousel-image {
      max-height: $cw-carousel-image-height !important;
    }
  }
}

@include media-breakpoint-down(sm) {
  .cw-carousel-subtext {
    min-height: $cw-carousel-subtext-small-height !important;
  }

  .carousel-image {
    min-height: $cw-carousel-image-height !important;
  }
}

.paragraph-width--narrow,
.paragraph-width--medium {
  .cw-image-left {
    display: flex;
    flex-direction: column-reverse;
  }

  .carousel-image {
    min-height: $cw-carousel-image-height !important;
  }
}

.paragraph-width--medium {
  .cw-carousel-subtext {
    min-height: $cw-carousel-subtext-medium-height !important;
  }
}

.carousel-control-prev,
.carousel-control-next {
  width: $cw-carousel-control-width !important;
}
