/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Other CiviCRM miscelaaneous overrides.
 */
/* stylelint-disable scss/at-extend-no-missing-placeholder */
/* stylelint-disable selector-max-id, max-nesting-depth, selector-max-compound-selectors, selector-no-qualifying-type*/

div.crm-container {
  #membership-listings,
  .crm-group {
    margin-bottom: $civicrm-crm-group-bottom-margin;
  }

  #ccButton {
    @extend .btn;
    @extend .btn-primary;
    text-shadow: none;

    // Hide any subsequent buttons added by JS.
    ~ #ccButton {
      display: none;
    }
  }

  .crm-form-radio[disabled] {
    ~ .crm-clear-link {
      display: none;
    }
  }

  .crm-inline-error,
  .crm-error {
    &:not(.messages):not(.crm-error-label) {
      display: inline-block;
      font-size: $civicrm-inline-error-font-size;
      font-weight: $civicrm-inline-error-font-weight;
      padding: $civicrm-inline-error-padding 0;
    }

    &.crm-error-label {
      padding: 0;
    }

    @include alert-variant(transparent, $alert-danger-border, $alert-danger-text);
  }
}
