/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/*
 * Extracted from: https://gist.github.com/Thoniur/cb653447f793865ded1591a959a5c1a7
 * Based on: https://gist.github.com/miwahall/7028640
 * And the idea of gordon-matt
 *
 * Works with Bootstrap 5.0.0-beta2, the Icons used are also new Bootstrap Svg Icon set (1.3.0)
 * The final design isn't 110% perfect.
 *
 * Used libs:
 *
 * jquery-3.5.1.min.js
 * jquery-ui.min.js	(1.12.1)
 * bootstrap.min.cs	(5.0.0-beta2)
 * bootstrap.min.js	(5.0.0-beta2)
 * bootstrap-icons.css	(1.3.0)
 */
/* stylelint-disable */
.ui-datepicker {
  background-color: #fff !important;
  border-radius: 4px;
  box-shadow: none;
  display: none;
  padding: 10px;
  width: 250px;
  &.ui-datepicker-inline {
    width: 100%;
  }
  .ui-datepicker-header {
    margin-bottom: 4px;
    text-align: center;
    border-radius: 4px;
    padding: 2px;
  }
  .ui-datepicker-title {
    font-weight: 700;
  }
  .ui-datepicker-prev {
    float: left;
    text-align: left;
    padding-left: 7px;
  }
  .ui-datepicker-next {
    float: right;
    text-align: right;
    padding-right: 7px;
  }
  .ui-icon {
    display: none;
  }
  .ui-datepicker-calendar {
    table-layout: fixed;
    width: 100%;
    td {
      border-radius: 4px;
      -webkit-transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
      -moz-transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
      -o-transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
      transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
      &:hover {
        background-color: #eee;
        cursor: pointer;
      }
      a {
        text-decoration: none;
        text-align: center;
        background: none;
        border: 0;
      }
    }
    .ui-datepicker-unselectable {
      background-color: #eff0f1;
      cursor: default;
      &:hover {
        background-color: #eff0f1;
        cursor: default;
      }
    }
  }
  .ui-datepicker-current-day {
    a {
      color: $dark;
    }
  }
}
.ui-widget-header {
  background: none;
  border: 0;
  .ui-icon {
    background-image: none;
  }
}
.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  display: none;
}
.ui-datepicker a,
.ui-datepicker a:hover {
  text-decoration: none;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: normal;
  height: 26px;
  line-height: 1;
  width: 30px;
}
.ui-datepicker .ui-datepicker-prev-hover:hover,
.ui-datepicker .ui-datepicker-next-hover:hover {
  border-radius: 4px;
  background-color: #eee;
}
.ui-datepicker .ui-datepicker-calendar th,
.ui-datepicker .ui-datepicker-calendar td {
  text-align: center;
  padding: 4px 0;
}
.ui-datepicker-prev {
  span {
    display: none;
  }
  &::before {
    display: inline-block;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='1em' height='1em' viewBox='0 0 16 16' class='bi bi-caret-left-fill' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.86 8.753l5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 1rem 1.5rem;
    width: 1rem;
    height: 1.7rem;
  }
}
.ui-datepicker-next {
  &::after {
    display: inline-block !important;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='1em' height='1em' viewBox='0 0 16 16' class='bi bi-caret-right-fill' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 1rem 1.5rem;
    width: 1rem;
    height: 1.7rem;
  }
}
.dp-highlight {
  .ui-state-default {
    background: $secondary !important;
    color: $light !important;
  }
}
.ui-state-highlight, .ui-widget-content .ui-datepicker-today {
  background: lighten($secondary, 50%);
}
/* stylelint-enable */
