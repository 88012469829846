/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * `ssp-contact-card` style component.
 * Used in Members directory and committees listing.
 */

.ssp-contact-card {
  position: relative;

  h4,
  &__title {
    flex-grow: 1;
    margin-bottom: $ssp-contact-card-title-bottom-margin;
    margin-top: 0;

    a {
      color: inherit;
    }
  }

  &__description {
    color: $ssp-contact-card-description-text-color;
    margin-bottom: $ssp-contact-card-description-bottom-margin;
  }

  &__post-description {
    color: $ssp-contact-card-post-description-text-color;
    font-size: $ssp-contact-card-post-description-font-size;
    line-height: $ssp-contact-card-post-description-line-height;
  }

  &__view {
    margin-top: $ssp-contact-card-title-bottom-margin;

    .fa {
      font-size: $ssp-contact-card-button-icon-font-size;
      margin-right: $ssp-contact-card-button-icon-spacing;
    }
  }

  .ssp-contact-card-container--with-left-image & {
    &.well {
      flex-direction: row;
      padding: $ssp-contact-card-well-padding;
    }

    .ssp-full-card__left-image {

      @include unwell('top-left-bottom', $ssp-contact-card-well-padding);
      @include user-image-placeholder(
        $ssp-contact-card-image-height,
        $ssp-contact-card-no-image-icon-font-size,
        $ssp-contact-card-no-image-icon-color,
        $ssp-contact-card-no-image-background
      );
      margin-right: $ssp-contact-card-well-padding;
      width: $ssp-contact-card-image-width;

      img { /* stylelint-disable-line selector-max-compound-selectors */
        height: 100%;
        object-fit: cover;
      }
    }

    .ssp-full-card__content {
      min-height: $ssp-contact-card-image-height - 2 * $ssp-contact-card-well-padding;
      overflow: hidden;
    }

    h4 {
      font-size: $ssp-contact-card-title-font-size;
      font-weight: $ssp-contact-card-title-font-weight;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ssp-contact-card__description {
      font-size: $ssp-contact-card-description-font-size;
    }
  }
}

.ssp-card__link {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
