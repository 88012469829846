/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Card styles overrides.
 */

// Use fixed height for leading card background image.
.leading-card-background {
  background-size: cover;
  height: $cw-leading-card-background-height;
}

.card-background {
  background-size: cover;
  height: $cw-card-background-height;
}

@include media-breakpoint-up(md) {
  .leading-card-background {
    height: $cw-leading-card-background-height-md;
  }

  .card-background {
    height: $cw-card-background-height-md;
  }
}

.card--type-event {
  background-color: $cw-card-event-background-color;
  border-color: $cw-card-event-border-color;
  color: $cw-card-event-color;

  .card-title,
  .card-text {
    color: $cw-card-event-color;
  }

  .card-date {
    color: $cw-card-date-color;
  }
}

.card--type-news {
  background-color: $cw-card-news-background-color;
  border-color: $cw-card-news-border-color;
  color: $cw-card-news-color;

  .card-text {
    color: $cw-card-news-color;
  }
}

.card--type-resource {
  background-color: $cw-card-resource-background-color;
  border-color: $cw-card-resource-border-color;
  color: $cw-card-resource-color;

  .card-text {
    color: $cw-card-resource-color;
  }
}

.card--type-opportunity {
  background-color: $cw-card-opportunity-background-color;
  border-color: $cw-card-opportunity-border-color;
  color: $cw-card-opportunity-color;

  .card-text {
    color: $cw-card-opportunity-color;
  }
}

.shadow-sm-hover:hover {
  box-shadow: $box-shadow-sm !important;
}

.shadow-hover:hover {
  box-shadow: $box-shadow !important;
}

.shadow-lg-hover:hover {
  box-shadow: $box-shadow-lg !important;
}

.shadow-none-hover:hover {
  box-shadow: none !important;
}

.shadow-sm-hover,
.shadow-hover,
.shadow-lg-hover,
.shadow-none-hover {
  transition: $shadow-hover-transition;
}

// FEM-676: create a theme variable to control the card radius for the following
// card types.
.card--type-event,
.card--type-news,
.card--type-resource,
.card--type-opportunity,
.card--type-external-product,
.card--type-team,
.card--type-navigation,
.card--type-block-quote,
.card--type-attachments {
  @include border-radius($cw-card-border-radius);
  overflow: hidden;
}

// FEM-753: Remove the border for the following card types.
.card--type-event,
.card--type-news,
.card--type-resource,
.card--type-opportunity {
  border: 0 !important;
}

// DRW-226: Navigation card anchor link issue.
.card--type-navigation {
  position: relative;
}
