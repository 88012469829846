/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Accordion styles overrides.
 */

$accordion-custom-button-icon:         url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-square-fill" viewBox="0 0 16 16"><path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z"/> </svg>') !default;
$accordion-custom-button-active-icon:  url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash-square-fill" viewBox="0 0 16 16"><path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 7.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1z"/></svg>') !default;

.accordion__faq-list {
  .accordion-item {
    background: none;
    border: 0;
    border-bottom: 1px solid $black;
    border-radius: 0;

    .accordion-button {
      background: none;
      box-shadow: none;
      font-size: $h5-font-size;
      padding: map-get($spacers, 1) 0;

      &::after {
        background-image: $accordion-custom-button-active-icon;
        background-size: $carousel-control-icon-width;
        height: $carousel-control-icon-width;
        width: $carousel-control-icon-width;
      }
    }

    .accordion-button.collapsed {
      &::after {
        background-image: $accordion-custom-button-icon;
      }
    }

    .accordion-body {
      font-size: $font-size-base;
      padding: map-get($spacers, 1) 0;
    }

    span.field-content {
      color: $black;
    }

    div.field-content {
      color: $black;
      font-weight: $display-font-weight;
    }
  }
}


