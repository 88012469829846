/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Container styles overrides.
 */

// CW Container mixin was created from
// https://github.com/twbs/bootstrap/blob/v5.1.3/scss/_containers.scss#L13
// A lot of logic was removed because we don't need to generate container
// variant classes e.g. container-sm, container-md ...
@mixin cw-make-container($grid-breakpoints, $container-max-widths) {
  // Responsive containers that are 100% wide until a breakpoint
  @each $breakpoint, $container-max-width in $container-max-widths {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      &.container {
        max-width: $container-max-width;
      }
    }
  }
}

.container--narrow {
  @include cw-make-container($cw-narrow-breakpoints, $cw-narrow-max-widths);
  @include make-grid-columns($grid-columns, $grid-gutter-width, $cw-narrow-breakpoints);
}

.container--medium {
  @include cw-make-container($cw-medium-breakpoints, $cw-medium-max-widths);
  @include make-grid-columns($grid-columns, $grid-gutter-width, $cw-medium-breakpoints);
}

.container--wide {
  @include cw-make-container($cw-wide-breakpoints, $cw-wide-max-widths);
  @include make-grid-columns($grid-columns, $grid-gutter-width, $cw-wide-breakpoints);
}

.container--full {
  @include cw-make-container($cw-full-breakpoints, $cw-full-max-widths);
  @include make-grid-columns($grid-columns, $grid-gutter-width, $cw-full-breakpoints);
}

// FEM-749: Update the breakpoints if the container inside a column that take up %50 width of screen.
.column-width--50-viewport-width {
  .container--narrow {
    @include cw-make-container($cw-narrow-inside-50vw-column-breakpoints, $cw-narrow-max-widths);
    @include make-grid-columns($grid-columns, $grid-gutter-width, $cw-narrow-inside-50vw-column-breakpoints);
  }

  .container--medium {
    @include cw-make-container($cw-medium-inside-50vw-column-breakpoints, $cw-medium-max-widths);
    @include make-grid-columns($grid-columns, $grid-gutter-width, $cw-medium-inside-50vw-column-breakpoints);
  }

  .container--wide {
    @include cw-make-container($cw-wide-inside-50vw-column-breakpoints, $cw-wide-max-widths);
    @include make-grid-columns($grid-columns, $grid-gutter-width, $cw-wide-inside-50vw-column-breakpoints);
  }

  .container--full {
    @include cw-make-container($cw-full-inside-50vw-column-breakpoints, $cw-full-max-widths);
    @include make-grid-columns($grid-columns, $grid-gutter-width, $cw-full-inside-50vw-column-breakpoints);
  }
}

.section-width--full {
  .container--narrow {
    @include cw-make-container($cw-narrow-inside-full-section-breakpoints, $cw-narrow-max-widths);
    @include make-grid-columns($grid-columns, $grid-gutter-width, $cw-narrow-inside-full-section-breakpoints);
  }

  .container--medium {
    @include cw-make-container($cw-medium-inside-full-section-breakpoints, $cw-medium-max-widths);
    @include make-grid-columns($grid-columns, $grid-gutter-width, $cw-medium-inside-full-section-breakpoints);
  }

  .container--wide {
    @include cw-make-container($cw-wide-inside-full-section-breakpoints, $cw-wide-max-widths);
    @include make-grid-columns($grid-columns, $grid-gutter-width, $cw-wide-inside-full-section-breakpoints);
  }

  .container--full {
    @include cw-make-container($cw-full-inside-full-section-breakpoints, $cw-full-max-widths);
    @include make-grid-columns($grid-columns, $grid-gutter-width, $cw-full-inside-full-section-breakpoints);
  }
}

.container--card-paragraphs-inside-node {
  @include cw-make-container($cw-card-paragraphs-breakpoints-inside-node, $cw-medium-max-widths);
  @include make-grid-columns($grid-columns, $grid-gutter-width, $cw-card-paragraphs-breakpoints-inside-node);
}
