/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * My case file upload block related styles
 */

.custom-dropzone-section {
  border: $ssp-my-organisations-border;
  padding: $ssp-horizontal-spacing !important;

  .dropzone-controls {
    padding-top: $ssp-horizontal-spacing !important;
  }
}

.ssp-case-form-actions {
  width: $ssp-my-case-file-upload-width;

  .dropzone-upload {
    float: right;
  }
}

.dropzone-files-preview {
  width: $ssp-my-case-file-upload-width !important;
}

.dropzone-toolbar {
  .dropzone-start {
    display: none;
  }
}

.case-activity-subject-form {
  padding-top: $ssp-horizontal-spacing !important;
}

.dropzone-item.d-flex {
  display: flex !important;
}

.ssp_core_case_dropzonejs_upload {
  border: $ssp-my-organisations-border;
  max-height: $ssp-my-case-file-upload-max-height;
  overflow: scroll;
}

.dz-default.dz-message,
.ssp-d-none.ssp-case-form-actions {
  display: none !important;
}

.dz-remove {

  @include hover {
    text-decoration: none;
  }
  color: $gray;
}

