/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Drupal views exposed form styles and layout tweaks.
 */

.views-exposed-form {
  .cw-field-icon-left,
  .cw-field-icon-right {
    .fa,
    .far {
      color: $gray-500;
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  .cw-field-icon-left {
    input {
      padding-left: $cw-filters-icon-adjustment-spacing;
    }

    .fa {
      left: $cw-filters-icon-horizontal-spacing;
    }
  }

  .cw-field-icon-right {
    input {
      padding-right: $cw-filters-icon-adjustment-spacing;
    }

    .fa,
    .far {
      right: $cw-filters-icon-horizontal-spacing;
    }
  }

  input.select2-input {
    width: auto !important;
  }

  .views-widget {
    position: relative;
  }

  .views-widget-filter-circle [class*='form-item'] {
    margin-right: $spacer / 2;
  }

  .views-exposed-form-outer {
    align-items: center;
    display: flex;

    .views-exposed-widgets {
      display: flex;
      flex-grow: 1;
    }

    .views-exposed-widget {
      margin-right: $cw-filters-fields-horizontal-spacing;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .views-exposed-form-inner {
    .views-widget-filter-circle {
      padding-right: 0;
    }

    .option {
      font-weight: normal;
    }
  }

  .geofield-proximity-field-wrapper {
    .form-item {
      float: none;
      margin: 0;
    }
  }

  input.hasDatepicker {
    @extend .form-control; /* stylelint-disable-line scss/at-extend-no-missing-placeholder */
  }
}
