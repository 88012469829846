/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/*
 * Custom styles for Infusion JS framework.
 */
/* stylelint-disable selector-max-compound-selectors */
.fl-prefsEditor-separatedPanel {
  border: 0;
  display: flex;

  .fl-panelBar {
    background-color: $cw-fluid-panel-bar-background-color;
    border-bottom: 0;
  }

  .fl-panelBar .fl-prefsEditor-buttons {
    background-color: transparent;
    border: 0;
    box-shadow: unset;
    padding-top: $cw-fluid-panel-bar-buttons-padding-top;
  }

  .fl-prefsEditor {
    color: $primary;
    font-family: $font-family-base;
  }

  .fl-prefsEditor-panels .fl-prefsEditor-panel {
    border: $cw-fluid-panel-border;
    border-radius: $cw-fluid-panel-border-radius;
    box-shadow: $cw-fluid-panel-box-shadow;
    padding: $cw-fluid-panel-padding;

    h2 {
      align-items: $cw-fluid-panel-h2-align-items;
      border-color: $cw-fluid-panel-h2-border-color;
      box-shadow: $cw-fluid-panel-h2-box-shadow;
      height: $cw-fluid-panel-h2-height;
      margin: $cw-fluid-panel-h2-margin;
      min-height: $cw-fluid-panel-h2-min-height;
      text-transform: $cw-fluid-panel-h2-text-transform;

      .fl-heading-text {
        font-size: $cw-fluid-panel-heading-text-font-size;
        line-height: $cw-fluid-panel-heading-text-line-height;
      }
    }

    .fl-textfieldStepper .fl-textfieldStepper-focusContainer {
      align-items: start;
    }

    .fl-prefsEditor-panel-descr {
      height: $cw-fluid-panel-descr-height;
    }
  }
}
/* stylelint-enable selector-max-compound-selectors */

.flc-slidingPanel-toggleButton,
.flc-prefsEditor-reset {
  background-color: transparent !important;
  border: 0 !important;
  border-radius: $cw-fluid-panel-bar-button-border-radius;
  color: var(--bs-primary);
  padding: $cw-fluid-panel-bar-button-padding !important;
  transition: $cw-fluid-panel-bar-buttons-hover-transition;

  span {
    padding-left: $cw-fluid-panel-bar-button-span-padding-left;
    padding-right: $cw-fluid-panel-bar-button-span-padding-right;
  }
}

.flc-prefsEditor-reset {
  margin-right: $cw-fluid-panel-bar-reset-button-margin-right;

  span {
    padding-right: 0;
  }
}

.flc-slidingPanel-toggleButton:hover,
.flc-prefsEditor-reset:hover {
  background-color: $cw-fluid-panel-bar-button-background-color !important;
}

.fl-prefsEditor {
  --fl-prefsEditorBgColor: #{$cw-fluid-panel-bar-background-color};
  font-family: $font-family-base;

  select {
    background-image: escape-svg($cw-fluid-panel-form-select-indicator);
    border-color: rgba($primary, 0.2);
    border-width: $cw-fluid-panel-form-select-border-width;
    color: $primary;
    width: $cw-fluid-panel-form-select-width;
  }

  .fl-textfieldStepper {
    align-items: $cw-fluid-panel-form-text-field-stepper-align-items;
  }

  .fl-textfieldStepper .fl-textfieldStepper-button {
    border-color: var(--bs-primary);
    color: var(--bs-primary);
  }

  .fl-textfieldStepper .fl-textfieldStepper-textField {
    border-color: rgba($primary, $cw-fluid-panel-form-text-field-border-color-alpha);
    border-radius: $cw-fluid-panel-form-text-field-border-radius;
    color: var(--bs-primary);
    height: $cw-fluid-panel-form-text-field-height;
  }

  .fl-textfieldStepper-focusContainer {
    padding: $cw-fluid-panel-form-text-field-stepper-focus-container-padding;
  }

  .fl-prefsEditor-onoff {
    .fl-switchUI-control {
      border-color: var(--bs-primary);
    }

    .fl-switchUI-controlKnob {
      background-color: var(--bs-primary) !important;
    }
  }
}

.flc-slidingPanel-panel .fl-blocking-theme {
  --fl-prefsEditorBgColor: #{$cw-fluid-panel-bar-background-color};
}

.fl-blocking-font {
  --fl-font-family: #{$font-family-base};
}

.fl-textSize-enabled legend {
  font-size: var(--fl-textSize) !important;
}

.cw-text-size-icon,
.cw-line-space-icon,
.cw-text-font-icon,
.cw-contrast-icon,
.cw-table-of-content-icon,
.cw-enhance-inputs-icon {
  margin-bottom: $cw-fluid-panel-icon-margin-bottom;
  margin-right: $cw-fluid-panel-icon-margin-right;
}

.fl-lineSpace-enabled {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    line-height: var(--cw-headers-line-space) !important;
  }

  @each $display, $font-size in $display-font-sizes {
    .display-#{$display} {
      line-height: var(--cw-headers-line-space) !important;
    }
  }
}

@media screen and (min-width: 40em) {
  .fl-prefsEditor-separatedPanel {
    .fl-panelBar {
      height: $cw-fluid-panel-bar-height;
    }

    .fl-panelBar .fl-prefsEditor-buttons {
      margin-right: $cw-fluid-panel-bar-buttons-margin-right;
    }

    .fl-panelBar.fl-panelBar-wideScreen {
      padding-bottom: $cw-fluid-panel-bar-padding-bottom;
      padding-top: $cw-fluid-panel-bar-padding-top;
    }

    .fl-prefsEditor-panels {
      margin: $cw-fluid-panels-margin;

      .fl-prefsEditor-panel {
        min-width: $cw-fluid-panel-min-width;
      }
    }
  }
}
