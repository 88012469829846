/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Quicktabs vertical tabs.
 */
.quicktabs-hide {
  display: none;
}

.quicktabs-wrapper.vertical-tabs {
  display: flex;
}

// For vertical tabs in mobile devices.
@media(max-width: $ssp-breakpoint-md) {
  .quicktabs-wrapper.vertical-tabs {
    display: contents !important;
  }
}

.vertical-tabs {
  ul.quicktabs-tabs {
    border-top: $exhibitor-listing-border !important;
    flex: $ssp-vertical-quicktabs-tabs-flex;
    margin-top: 0;
    padding-left: 0 !important;

    a {
      border: $exhibitor-listing-border;
      border-radius: $exhibitor-listing-border-radius;
      color: $ssp-brand-gray !important;
      cursor: pointer;
      display: inline-block;
      font-weight: $ssp-font-weight-bold;
      padding: 0 !important;
      text-decoration: none;
      width: $exhibitor-quicktabs-tabs-link-width;
    }

    li {
      background: none;
      background-color: $ssp-brand-white;
      border-bottom: $ssp-my-organisations-border !important;
      border-left: $ssp-my-organisations-border !important;
      border-right: $ssp-my-organisations-border !important;
      display: block;
      list-style-type: none;
      padding: $ssp-vertical-quicktabs-padding;
      white-space: nowrap;
    }

    a:focus {
      outline: none;
    }
  }

  li.active,
  li:hover {
    background-color: $brand-primary !important;

    a {
      color: $ssp-brand-white !important;
    }
  }

  .vertical-tabs-panes {
    flex: $ssp-vertical-quicktabs-pane-flex;
    margin: $ssp-vertical-quicktabs-margin;
  }
}
