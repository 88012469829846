/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

ul li.expanded {
  list-style-image: none;
  list-style-type: none;
  padding: unset;
}

ul li.collapsed {
  list-style-image: none;
  list-style-type: none;
}

ul li.leaf {
  list-style-image: none;
  list-style-type: none;
}

.cw-logout-container {
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.cw-menu-popup-container {
  @include media-breakpoint-up(lg) {
    right: $cw-menu-popup-container-position-right;
  }
  margin-top: $cw-menu-popup-container-margin-top;
  padding-top: $cw-menu-popup-container-padding-top;
  position: absolute;
  right: 0;
  top: 0;
  z-index: $cw-menu-popup-container-z-index;
}

.cw-login-menu-popup {
  max-width: $cw-login-menu-popup-max-width;
  width: 100%;
}

.cw-menu-popup-container .alert,
.cw-menu-popup-container .btn-close {
  padding: $cw-login-menu-popup-alert-box-padding;
}

.cw-login-menu-popup hr,
.cw-login-or-register-block hr {
  margin: $spacer * 0.5;
}

.cw-main-menu {
  @include media-breakpoint-down(lg) {
    width: auto !important;
  }
}

.left-secondary-menu {
  @include media-breakpoint-down(lg) {
    font-size: $font-size-base !important;
  }
}

.navbar-light .navbar-nav .nav-link:hover {
  color: rgba($black, 0.9);
  text-decoration: underline;
}

.cw-search-input {
  @include border-radius($btn-border-radius);
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: inherit;
}

/* Ripple effect navigation links */
.nav-link-ripple {
  @include media-breakpoint-down(lg) {
    background-position: center;
    transition: background 0.4s;
  }
}

.nav-link-ripple:hover {
  @include media-breakpoint-down(lg) {
    background: radial-gradient(circle, transparent 1%, $white 1%) center/15000%;
  }
}

.nav-link-ripple:active {
  @include media-breakpoint-down(lg) {
    background-color: rgba($primary, 0.2);
    background-size: 100%;
    transition: background 0s;
  }
}

.bi-person {
  color: $primary;
}

.navbar-toggler {
  >.close {
    display: inline;
  }
}

.navbar-toggler.collapsed>.close,
.navbar-toggler:not(.collapsed)>.toggle-icon {
  display: none;
}

a.active.active-parent {
  @include media-breakpoint-down(lg) {
    border-left: 0;
  }
}

.nav-link-ripple.active {
  @include media-breakpoint-down(lg) {
    border-left: $cw-navbar-links-border;
    color: $primary !important;
    font-weight: $font-weight-bold;
  }
}

.dropdown>[aria-expanded='true'] {
  >.cw-submenu-icon {
    transform: rotate(90deg);
  }
}

.cw-dropdown-hover:hover {
  .dropdown-menu {
    @include media-breakpoint-up(lg) {
      display: block;
      margin-top: 0;
    }
  }
}

// Overrides list anchor color in Drupal system.menus.css file.
.pagination {
  > li {
    > a.active {
      color: $pagination-color;
    }
  }
}
