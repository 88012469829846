/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */


.view-display-id-block[class*='cw-organisation-member-directory'] {
  @include media-breakpoint-up(md) {
    .ip-geoloc-map {
      padding-left: ($spacer / 4);
    }

    .attachment {
      .view[class*='cw-organisation-member-directory'] {
        float: right;
        width: 50%;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .ip-geoloc-map {
      padding-bottom: ($spacer / 2);
    }

    .views-bootstrap-grid-plugin-style {
      width: 100% !important;
    }

    .attachment {
      .view[class*='cw-organisation-member-directory'] {
        width: 100%;
      }
    }
  }

  .views-bootstrap-grid-plugin-style {
    float: left;
    height: $cw-map-view-attachment-height;
    overflow-y: scroll;
  }

  .views-bootstrap-grid-plugin-style::-webkit-scrollbar {
    width: $cw-scrollbar-width;
  }

  .views-bootstrap-grid-plugin-style::-webkit-scrollbar-thumb {
    background-color: $gray-500;
    border-radius: $cw-scrollbar-width;
  }

  .views-widget-filter-country_id {
    display: none;
  }

  .gm-ui-hover-effect {
    // Greater than .stretched_link z-index which is 1.
    z-index: 2;
  }
}


.view-display-id-block[class*='cw-organisation-member-directory-card-only'] {
  .card-img-top {
    min-height: $cw-card-view-image-height;
  }

  .views-bootstrap-grid-plugin-style {
    float: none !important;
    height: auto !important;
    overflow-y: unset !important;
  }
}
