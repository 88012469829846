/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Drupal views exposed form styles and layout tweaks.
 */

.views-exposed-form .views-exposed-widget {
  float: none;
  padding: 0;
}

.views-exposed-form {

  @include for-phone-only() {
    .well {
      .views-exposed-widgets {
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
      }

      .views-exposed-widget {
        margin-right: 0;
        margin-top: $ssp-header-vertical-padding;
      }
    }

    .ssp-widget-count-many {
      flex-direction: column;

      .views-exposed-widgets {
        width: 100%;
      }

      .views-exposed-widget {
        flex-grow: 1;
      }

      .form-item {
        max-width: 100%;

        /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
        input,
        .btn {
          width: 100%;
        }
        /* stylelint-enable */
      }

      .ssp-toggle-filters {
        margin-top: $ssp-bootstrap-well-padding;
      }
    }
  }

  .views-exposed-widgets {
    margin-bottom: 0;

    .form-group {
      margin-bottom: 0;
    }

    .views-exposed-widget {
      margin-right: $ssp-filters-lateral-spacing;

      // setting width of other input elements in exposed filters
      &:not(.views-reset-button):not(.views-submit-button):not(.views-widget-date-combined):not(.ssp-separated-search) {
        width: $ssp-filters-input-buttons-width;
      }
    }
  }

  .aligned-widgets {
    align-items: flex-start;
    display: flex;

    .views-exposed-widgets {
      flex-wrap: wrap;
      row-gap: $ssp-filters-lateral-spacing;
    }
  }

  .ssp-field-icon-left,
  .ssp-field-icon-right {
    .fa,
    .far {
      color: $ssp-bootstrap-form-control-placeholder;
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  .ssp-field-icon-left {
    input {
      padding-left: $ssp-filters-icon-adjustment-spacing;
    }

    .fa {
      left: $ssp-filters-icon-horizontal-spacing;
    }
  }

  .ssp-field-icon-right {
    input {
      padding-right: $ssp-filters-icon-adjustment-spacing;
    }

    .fa,
    .far {
      right: $ssp-filters-icon-horizontal-spacing;
      top: $ssp-filters-icon-verticle-spacing;
    }
  }

  .views-widget,
  .view-date-widget {
    position: relative;
  }

  .view-date-widget {
    width: $ssp-filters-date-field-width;
  }

  .well {
    background: transparent;

    .views-exposed-widgets {
      align-items: center;
      display: flex;
    }
  }

  .not-collapsible {
    .well {
      border: 0;
      box-shadow: none;
      padding: 0;
    }
  }

  .ssp-long-search-filter {
    .form-item {
      max-width: $ssp-long-filters-input-buttons-width;
    }
  }

  .views-reset-button {
    flex-grow: 1;
    text-align: right;

    .icon {
      display: none;
    }

    .btn {

      @include hover {
        text-decoration: underline;
      }
      background: transparent;
      border: 0;
      color: $ssp-brand-link-color;
      font-weight: $ssp-font-weight-normal;
      margin: 0;
      padding: 0;
    }
  }

  .views-widget-date-combined {
    .views-widget {
      align-items: center;
      display: flex;
    }
  }

  .views-exposed-form-outer {
    align-items: flex-end;
    display: flex;
    padding-bottom: $ssp-bootstrap-well-padding;

    .views-exposed-widgets {
      display: flex;
      flex-grow: 1;
    }

    .views-exposed-widget {
      margin-right: $ssp-filters-fields-horizontal-spacing;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.ssp-toggle-filters {

  @include hover {
    text-decoration: none;
  }
  margin: 0 0 $ssp-filters-show-hide-bottom-spacing $ssp-filters-fields-horizontal-spacing;
  white-space: nowrap;
}

.ssp-field-separator {
  font-weight: $ssp-separator-font-weight;
  margin: 0 $ssp-filters-field-separator-spacing;
}
