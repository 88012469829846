/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Autocomplete styles overrides.
 */

// stylelint-disable-next-line selector-max-id
#autocomplete {
  background-color: $white;
  border-top: 0;
  padding: 0 $cw-autocomplete-padding-x;
}

// stylelint-disable-next-line selector-max-id
#autocomplete li.selected {
  background: $gray-100;
  border-left: $cw-autocomplete-padding-x solid $primary;
  color: $black;
}

html.js input.form-autocomplete {
  background-image: none;
}

html.js input.throbbing {
  background-image: url('/misc/throbber-active.gif');
  background-position: 95% center;
}
