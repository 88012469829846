/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

.select2-container-multi {
  .select2-choices {
    appearance: none;
    background-clip: padding-box;
    background-color: #fff;
    background-image: none;
    border: 1px solid $gray-400;
    border-radius: 0.25rem;
    display: block;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;

    input {
      color: #6c757d !important;
      padding: 0.375rem 0.75rem !important;
    }

    .select2-search-choice {
      background-image: none !important;
      padding: 0.5rem 0.75rem 0.5rem 1.25rem;
    }
  }

  .select2-grid &.select2-container {
    min-width: unset;
  }

  &.select2-container-active {
    .select2-choices {
      box-shadow: none;
    }
  }

  .select2-search-choice-close {
    top: 9px;
  }
}

.select2-container {
  width: 100% !important;

  .select2-choice {
    appearance: none;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-position: right 0.75rem center;
    background-repeat: no-repeat;
    background-size: 16px 12px;
    border: 1px solid $gray-400;
    border-radius: 0.25rem;
    color: #212529;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    height: auto;
    line-height: 1.5;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    width: 100%;

    .select2-arrow {
      display: none;
    }

    abbr {
      right: $ssp-no-results-sm-top-margin;
      top: $ssp-filters-field-separator-spacing;
    }
  }

  > .select2-chosen {
    margin-right: 0 !important;
  }

  &.select2-allowclear {
    .select2-choice {
      .select2-chosen {
        margin-right: 0;
      }
    }
  }
}
