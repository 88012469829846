/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * CiviCRM accordion elements overrides.
 */

/* stylelint-disable scss/at-extend-no-missing-placeholder */
/* stylelint-disable selector-max-id, max-nesting-depth, selector-max-compound-selectors, selector-no-qualifying-type*/
div.crm-container {
  .crm-accordion-header {
    @extend .bg-primary;
    border-radius: 0 !important;
    // Overriding the styles coming from webform bootstrap.
    display: flex !important;
    justify-content: space-between;
    padding: $ssp-accordion-header-padding;
    width: 100%;
  }

  .crm-accordion-body {
    border: 0;

    table.crm-info-panel {
      border: 0;
      margin: 0;

      &:not(:last-child) {
        @extend p;
      }

      > tbody {
        > tr {
          align-items: center;
          display: flex;

          > td {
            background-color: transparent;
            flex-basis: 50%;
            flex-grow: 1;
          }
        }
      }

      td.label {
        color: inherit;
        font-weight: inherit !important;
        margin: 0 !important;
      }
    }
  }
}
