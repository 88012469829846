/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/* stylelint-disable selector-no-qualifying-type, selector-max-compound-selectors, max-nesting-depth, selector-max-id */

.crm-container {
  .clear {
    clear: both;
  }

  &.crm-public {
    .crm-form-date-wrapper {
      display: grid;
      grid-auto-flow: column;
      grid-gap: $padding-small-horizontal;
    }

    input.crm-placeholder-icon::placeholder {
      @include fa-icon;

      text-align: right;
    }
  }

  .cividiscount-section {
    .crm-form-submit {
      margin-top: $padding-large-vertical;
    }
  }

  .crm-not-you-message {
    @include alert-block();
    @include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);
  }

  // Copy well styles from BS and Core webform.
  #intro_text,
  #footer_text {
    background-color: $well-bg;
    border: $wb-well-border;
    border-radius: $wb-well-border-radius;
    box-shadow: $wb-well-shadow;
    margin-bottom: 20px;
    min-height: 20px;
    padding: $wb-well-padding;

    blockquote {
      border-color: #ddd;
      border-color: rgba(0, 0, 0, 0.15);
    }
  }

  #ccButton {
    @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $btn-border-radius-base);
    @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
    border: 1px solid transparent;

    display: inline-block;
    font-weight: $btn-font-weight;
    margin-bottom: $padding-base-vertical;

    + #ccButton {
      display: none;
    }
  }

  .crm-profile-name-direct_debit_mandate {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -#{$wb-layout-box-field-spacing-x / 2};

    > * {
      padding-left: $wb-layout-box-field-spacing-x / 2;
      padding-right: $wb-layout-box-field-spacing-x / 2;
    }

    .legend {
      flex-basis: 100%;
    }

    > .form-item {
      @include for-tablet-portrait-up() {
        flex-basis: 50%;
      }

      flex-basis: 100%;
    }

    .form-item {
      .content {
        align-items: center;
        display: flex;
      }

      .crm-form-date-wrapper {
        align-items: center;
        display: flex;
      }

      .crm-form-date-wrapper,
      .crm-form-select {
        flex-basis: 100%;
      }

      .crm-clear-link,
      .crm-option-edit-link {
        margin-left: 5px;
      }
    }
  }

  .crm-event-manage-tab-actions-ribbon {
    > ul {
      display: flex;
      justify-content: space-between;
      list-style: none;
      padding: 0;

      > li {
        position: relative;

        ul {
          list-style: none;
          padding: 0;
        }

        .crm-i {
          cursor: pointer;
        }

        // Copy Dropdown menu styles.
        .ac_results {
          @include box-shadow(0 6px 12px rgba(0, 0, 0, 0.175));
          background-clip: padding-box;
          background-color: $dropdown-bg;
          border: 1px solid $dropdown-fallback-border; // IE8 fallback
          border: 1px solid $dropdown-border;
          border-radius: $border-radius-base;
          display: none; // none by default, but block on "open" of the menu
          float: left;
          font-size: $font-size-base;
          left: 0;
          list-style: none;
          margin: 2px 0 0; // override default ul
          min-width: 200px; // Changing min width to a larger value - default was 160
          padding: 5px 0;
          position: absolute;
          text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
          top: 100%;
          z-index: $zindex-dropdown;

          // Links within the dropdown menu
          li > a {
            clear: both;
            color: $dropdown-link-color;
            display: block;
            font-weight: 400;
            line-height: $line-height-base;

            // Overriding max width specifically
            max-width: 300px;
            padding: 3px 20px;
            white-space: normal; //Override to break links in new line

            &:hover,
            &:focus {
              background-color: $dropdown-link-hover-bg;
              color: $dropdown-link-hover-color;
              text-decoration: none;
            }
          }
        }

        #crm-participant-list {
          left: auto;
          right: 0;
        }
      }
    }
  }

  .fee_block-table {
    .price_set_option-label {
      font-weight: $wb-font-weight-bold;
    }

    .fee_amount-value {
      padding-left: $padding-base-horizontal;
    }
  }

  .event_info-group {
    .form-layout {
      tbody > tr {
        display: flex;
        flex-direction: column;
        margin-bottom: $form-group-margin-bottom;

        // Copy Label font weight
        td:first-child {
          font-weight: $wb-font-weight-bold;
          line-height: $line-height-base;
        }
      }
    }
  }

  .crm-event-info-form-block {
    @include clearfix();

    .crm-actionlinks-bottom {
      float: left;
      margin-bottom: $padding-large-vertical;
    }

    .event-info table:not(.form-layout):not(.form-layout-compressed) {
      margin: 0;
      width: 100%;

      &.crm-info-panel > tbody {
        tr {
          margin-bottom: $padding-large-vertical;
        }

        .label {
          text-align: left;
        }
      }
    }
  }

  .price-set-row {
    .crm-price-amount-label {
      font-weight: $wb-font-weight-bold;
    }
  }

  .crm-event-thankyou-form-block {
    // Hide line breaks as they break the layout and make it widespaced.
    br {
      display: none;
    }

    .event_info_link-section {
      float: left;
      margin-bottom: $padding-large-vertical;
    }
  }

  .crm-event-info-form-block,
  .crm-event-thankyou-form-block {
    .iCal_links-section {
      float: right;
      margin-bottom: $padding-large-vertical;
    }

    .crm-socialnetwork {
      clear: both;
    }
  }

  .crm-socialnetwork {
    p:not(:last-child) {
      margin-bottom: $padding-base-vertical;
    }

    .btn {

      @include for-tablet-portrait-up() {
        margin: 0;
      }
      margin: 0 0 $padding-small-vertical 0;

      &.btn-default {
        border-color: $btn-default-border;
      }
    }
  }

  .create_pcp_link-section,
  .tell_friend_link-section,
  .register_link-bottom,
  .register_link-top {
    .button {
      @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $btn-border-radius-base);
      @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
      background-color: $btn-primary-bg;

      border: 1px solid $btn-primary-bg;
      color: $btn-primary-color;

      display: inline-block;
      font-weight: $btn-font-weight;
      margin-bottom: $padding-base-vertical;
      text-decoration: none;

      &:hover,
      &:focus {
        background-color: $btn-primary-bg;
        color: $btn-primary-color;
        text-decoration: none;
      }
    }
  }

  .tell_friend_form-group {
    .form-layout-compressed {
      td.label {
        color: inherit;
        font-size: inherit;
        line-height: inherit;
        padding: 0;
        text-align: inherit;
      }

      > tbody {
        > tr {
          display: flex;

          &:first-child {
            p {
              @include alert-block();
              @include alert-variant($alert-success-bg, $alert-success-border, $alert-success-text);
            }
          }

          &:nth-child(2),
          &:nth-child(3) {
            flex-direction: column;
            margin-bottom: $form-group-margin-bottom;
          }

          &:nth-child(4) {
            margin-bottom: $form-group-margin-bottom;

            > td:not(:empty) {
              flex-grow: 1;

              table,
              .crm-form-text {
                width: 100%;
              }

              table {
                table-layout: fixed;

                td {
                  vertical-align: baseline;
                }
              }
            }
          }

          &:nth-child(5) {
            margin-bottom: $form-group-margin-bottom;

            td:last-child {
              display: flex;
              flex-direction: row-reverse;
              justify-content: space-between;
              width: 100%;
            }
          }
        }
      }
    }

    .tell_friend_emails-group {
      table {
        background-color: transparent;

        .columnheader {
          font-weight: $wb-font-weight-bold;
        }

        .crm-error {
          @include wb-label();
          @include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);

          background-color: none;
          display: inline-block;
        }
      }
    }
  }

  .CRM_PCP_Form_PCPAccount {
    > .help {
      @include alert-block();
      @include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);
    }
  }

  .CRM_PCP_Form_Campaign {
    .crm-accordion-header {
      @include wb-label();
    }

    .crm-error:not(.crm-error-label) {
      @include wb-label();
      @include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);

      display: inline-block;
    }

    td.label {
      color: inherit;
      font-size: inherit;
      line-height: inherit;
      padding: 0;
      text-align: inherit;
    }

    #attachments {
      .form-layout-compressed {
        width: 100%;
      }

      tr {
        display: flex;
        flex-direction: column;
      }
    }

    .crm-submit-buttons {
      .crm-button-type-upload {
        order: 1;
      }
    }
  }
}
