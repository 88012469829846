/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Core bootstrap variable overrides
 */

$enable-negative-margins: true;
$spacer: 2rem;
$font-weight-medium: 500;
$breadcrumb-divider: quote('|');
$pagination-padding-y: 0.375rem !default;
$pagination-padding-x: 1.125rem !default;
$pagination-color: #017bfe !default;
$pagination-border-width: 0 !default;
$pagination-active-color: #343a40 !default;
$pagination-active-bg: #dfdfdf !default;
$pagination-active-border-color: #dfdfdf !default;
$shadow-hover-transition: box-shadow 0.4s ease-in-out;
$box-shadow-sm: 0 0.5rem 1.5rem rgba(149, 157, 165, 0.2) !default;

$font-size-base:              1rem !default;
$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;
$h7-font-size:                $font-size-base * 0.875 !default;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $h7-font-size
) !default;
