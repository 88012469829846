/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * My organisation page related styles.
 */

.view-ssp-organisations-members {
  .checkbox,
  .radio {
    margin: 0;
  }

  .table-responsive {
    + .form-actions {
      margin-top: $ssp-table-form-action-button-spacing-y;
    }
  }

  .views-field-id {
    min-width: $ssp-table-organisations-field-id-width;
  }

  .views-field-phone {
    min-width: $ssp-table-organisations-field-phone-width;
  }

  .views-field-nothing {
    min-width: $ssp-table-organisations-field-optin-width;
  }

  .form-actions {
    .btn-outline-secondary {
      width: $sspx-cancel-btn-width;
    }
  }
}

.view-ssp-organisations-detail-page {
  .organisation-edit-details-link {
    margin: $ssp-button-icon-margin;
  }
}

.view-ssp-organisations-listing {
  .dropdown.remove-self-org {
    .btn.dropdown-toggle {
      border: 0;
      color: $ssp-applicant-card-action-button-color;
      float: right;
      position: absolute;
      right: $ssp-side-image-right-adjustment;
      top: calc(#{$ssp-side-image-top-adjustment * 6});
    }

    .dropdown-menu {
      left: calc(#{$ssp-content-body-vertical-margin * 5});
      top: calc(#{($ssp-side-image-top-adjustment * 4) + 2});
    }
  }

  .request-join-button {
    margin-bottom: $ssp-details-page-fields-bottom-margin;
  }

  .ssp-contact-card {
    min-height: $ssp-contact-card-well-height;
  }

}

.ssp-core-organisations-manage-member-form {
  .form-item-manage-member-relationships {
    max-height: $ssp-modal-manage-member-form-content-height;
    overflow-x: hidden;
    overflow-y: auto;

    .form-checkboxes {
      @include for-tablet-portrait-up() {
        column-count: 2;
      }
    }
  }
}

.webform-client-form {
  .add-member-webform-helptext {
    background: $ssp-warning-bg;
    padding: $ssp-button-padding-x;

    ul {
      margin-bottom: 0;
      padding-left: $ssp-cpd-list-item-padding-x;
    }
  }

  .wb-component-civicrm-contact-fieldset {
    .first-name {
      @include for-phone-only() {
        flex-basis: $ssp-cpd-panel-body-width;
      }
    }
  }

  .panel {
    @include for-phone-only() {
      margin: 0 !important;
    }
  }

  .webform-submit {
    @include for-phone-only() {
      width: $ssp-cpd-panel-body-width;
    }
  }
}

.node-type-cw-anchor-pages,
[class*='-organisations'] {
  .modal-default {
    top: $ssp-table-organisations-field-id-width !important;
  }

  div.ctools-modal-content {
    margin: $ssp-modal-manage-member-dialog-box-offset-y-md auto;

    .modal-content {
      max-height: $ssp-modal-manage-member-modal-height;
      overflow: scroll;
      padding: $ssp-bootstrap-well-padding;
    }

    .resizable-textarea {
      margin: 0;
    }

    .form-textarea {
      @extend .form-control; /* stylelint-disable-line scss/at-extend-no-missing-placeholder */
    }

    .close {
      &::before,
      &::after {
        content: none;
      }
    }
  }

  .form-type-date-popup {
    .help-block {
      display: none !important;
    }
  }
}

.block-quicktabs {
  table {
    border: $ssp-my-organisations-border !important;

    td {
      background-color: $ssp-brand-white !important;
      border-bottom: $ssp-my-organisations-border !important;
      border-top: 0 !important;
    }

    th {
      border-bottom: 0 !important;
    }
  }
}

.view-ssp-organisations-manage-members-request {
  margin-bottom: $ssp-vertical-spacing;
}

a.cw-organisation-news-view {
  border: $ssp-header-border solid transparent;
  border-radius: $ssp-button-padding-y;
  cursor: pointer;
  display: inline-block;
  font-weight: $ssp-font-weight-bold;
  padding: $ssp-button-padding-y $ssp-icon-quot-padding-mobile;
  text-decoration: none;
}

.ctools-modal-content {
  .node-cw_news-form,
  .node-cw_event-form,
  .node-cw_opportunity-form {
    .form-type-select {
      width: $ssp-cpd-managed-file-width !important;
    }

    fieldset.filter-wrapper {
      display: none !important;
    }
  }

  .button.browse {
    background: linear-gradient(to bottom, $ssp-my-organisations-white-color $ssp-my-organisations-linear-gradient-null, $ssp-my-organisations-gray-color $ssp-my-organisations-linear-gradient-full);
    border: $ssp-header-border solid $ssp-cancel-button-border-color;
    border-radius: $ssp-my-organisations-border-radius;
    color: $ssp-brand-gray-base !important;
    margin-left: $ssp-header-border;
    margin-top: $ssp-header-border;
    padding: $ssp-button-padding-y $ssp-filters-icon-adjustment-spacing;
    text-decoration: none;
    width: $ssp-contact-card-well-height;
  }

  .modal-content {
    @include for-phone-only() {
      overflow-x: hidden;
      overflow-y: auto;
      padding: $ssp-table-mobile-prefix-space;
      width: $ssp-cpd-panel-body-width !important;
    }
  }
}

.cw-organisation-news-view {
  .views-exposed-widgets {
    display: flex !important;
    padding-bottom: $ssp-horizontal-spacing !important;
  }

  .views-reset-button {
    .btn {
      position: relative;
      top: $ssp-icon-quot-padding;
    }
  }

  .views-widget-filter-created_1 {
    width: $ssp-filters-date-field-width !important;

    .views-widget {
      display: flex !important;
    }
  }

  .form-item-news-created-1,
  .form-item-opportunity-created-1,
  .form-item-event-created-1 {
    padding-right: $ssp-memberships-list-item-padding !important;
    width: $ssp-filters-date-field-width !important;

    input {
      cursor: pointer;
    }
  }

  .views-widget-filter-created_2 {
    padding-top: $ssp-my-organisations-date-padding-top !important;
    width: $ssp-filters-date-field-width !important;
  }

  .form-item-news-created-2,
  .form-item-opportunity-created-2,
  .form-item-event-created-2 {
    width: $ssp-filters-date-field-width !important;

    .control-label {
      display: none;
    }

    input {
      cursor: pointer;
    }
  }
}

a.cw-exhibitor-listing {
  border: $exhibitor-listing-border;
  border-radius: $exhibitor-listing-border-radius;
  cursor: pointer;
  display: inline-block;
  font-weight: $ssp-font-weight-bold;
  padding: $exhibitor-listing-padding;
  text-decoration: none;
}

ul.vertical-tabs-list {
  li {
    padding: $exhibitor-quicktabs-tabs-links-padding !important;
  }

  li.active,
  li:hover {
    background-color: $brand-primary;

    a {
      color: $ssp-brand-white !important;
    }
  }
}

.form-item-title {
  .description {
    display: none !important;
  }
}

.tabledrag-toggle-weight-wrapper {
  display: none !important;
}

.field-name-field-cw-product-additionalimage {
  .ctools-modal-media-file-edit {
    padding-right: $exhibitor-quicktabs-tabs-links-padding !important;
  }
}

.view-cw-products-exhibitor-listing,
.view-cw-brands-exhibitor-listing,
.view-cw-videos-exhibitor-listing {
  .views-reset-button {
    .btn {
      position: relative;
      top: $ssp-icon-quot-width;
    }
  }

  .views-exposed-widgets {
    display: flex;
  }

  .views-widget-filter-title {
    margin-top: $exhibitor-view-listing-date-margin-top !important;

    label {
      display: none;
    }
  }

  .tabledrag-toggle-weight {
    display: none !important;
  }

  a.tabledrag-handle {
    .handle {
      background-position-y: $exhibitor-tabledrag-handle-y-position !important;
      height: auto;
      margin: $exhibitor-tabledrag-handle-margin !important;
      padding: $exhibitor-tabledrag-handle-pading;
      width: $exhibitor-tabledrag-handle-width;
    }
  }

  a.tabledrag-handle:hover {
    text-decoration: none;
  }
}

.views-widget-filter-field_cw_product_updated_date_value_1,
.views-widget-filter-field_cw_brand_updated_date_value_1,
.views-widget-filter-field_cw_video_last_updated_date_value_1 {
  margin-top: $exhibitor-view-listing-date-margin-top !important;
  width: $exhibitor-view-listing-date-width !important;

  input {
    width: $exhibitor-view-listing-date-width !important;
  }
}

.views-widget-filter-field_cw_product_updated_date_value,
.views-widget-filter-field_cw_brand_updated_date_value,
.views-widget-filter-field_cw_video_last_updated_date_value {
  width: $exhibitor-view-listing-date-width !important;

  input {
    width: $exhibitor-view-listing-date-width !important;
  }

  .views-widget {
    display: inline-flex !important;

    .ssp-field-separator {
      margin: $exhibitor-view-listing-date-separator-x-margin $exhibitor-view-listing-date-separator-y-margin !important;
    }
  }
}

// For quicktabs content in mobile devices.
@media(max-width: $ssp-breakpoint-md) {
  [class*='-organisations'] {
    .modal-default {
      top: $exhibitor-listing-modal-top !important;
    }

    .ui-dialog.media-wrapper {
      width: auto !important;
    }

    .ssp-page-content {
      overflow: hidden !important;
    }
  }

  .quicktabs-wrapper {
    .views-exposed-widgets {
      display: contents !important;

      label {
        margin-top: $ssp-tabs-responsive-exposed-form-label-margin-top;
      }

      .views-widget-filter-title {
        display: contents !important;
      }

      .views-exposed-widget {
        float: left !important;
      }
    }

    .view-content {
      overflow-y: scroll;
    }

    .views-reset-button {
      .btn {
        position: relative;
        top: $exhibitor-listing-reset-button-top !important;
      }
    }
  }

  .views-widget-filter-field_cw_product_updated_date_value_1,
  .views-widget-filter-field_cw_brand_updated_date_value_1,
  .views-widget-filter-field_cw_video_last_updated_date_value_1 {
    margin-top: $exhibitor-view-listing-to-date-margin-top !important;
  }

  .views-widget-filter-created_2 {
    padding: $ssp-tabs-responsive-exposed-form-date-paddig !important;
  }
}

// For quicktabs content in mobile devices.
@media(max-width: $ssp-breakpoint-sm) {
  [class*='-organisations'] {
    .views-reset-button {
      float: none !important;

      .btn {
        top: auto !important;
      }
    }
  }

  table.draggable {
    tbody {
      td {
        white-space: normal !important;
      }
    }
  }
}

// For quicktabs content in mobile devices.
@media(max-width: $ssp-breakpoint-x-sm) {
  [class*='-organisations'] {
    .views-reset-button {
      .btn {
        position: initial !important;
      }
    }

    .modal-default {
      left: auto !important;

      .ctools-modal-content {
        width: auto !important;
      }
    }
  }
}
