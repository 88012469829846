/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Button styles overrides.
 */

$cw-content-type-colors: (
  'event':       $cw-btn-event-background-color,
  'news':        $cw-btn-news-background-color,
  'resource':    $cw-btn-resource-background-color,
  'opportunity': $cw-btn-opportunity-background-color,
);

@each $content-type, $value in $cw-content-type-colors {
  .btn-#{$content-type} {
    @include button-variant($value, $value);
  }
}
