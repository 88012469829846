/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Contains grid styles.
 */

.cw-two-column-layout-anchor-page-gutters {
  --#{$variable-prefix}gutter-x: 0;
  --#{$variable-prefix}gutter-y: #{$cw-two-column-layout-anchor-page-vertical-gutters};
}

.cw-column-left-padding {
  padding-left: 0;
}

.cw-column-right-padding {
  padding-right: 0;
}

@include media-breakpoint-up(md) {
  .cw-column-left-padding {
    padding-left: calc(#{$cw-two-column-layout-anchor-page-horizontal-gutters} / 2);
  }

  .cw-column-right-padding {
    padding-right: calc(#{$cw-two-column-layout-anchor-page-horizontal-gutters} / 2);
  }
}

/* stylelint-disable scss/at-extend-no-missing-placeholder -- It doesn't make sense to repeat the CSS rules here */
.cw-components-wrapper {
  @extend .d-flex;
  @extend .flex-column;
  @extend .h-100;
}
/* stylelint-enable scss/at-extend-no-missing-placeholder */
