/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Global site no result behaviour
 * styles.
 */
.ssp-no-results {
  margin-bottom: 0;
  margin-top: $ssp-no-results-top-margin;
  text-align: center;

  &::before {
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: $ssp-no-results-image-height;
    margin: 0 auto;
    width: $ssp-no-results-image-width;
  }

  &.ssp-no-results-sm {
    margin-top: $ssp-no-results-sm-top-margin;

    &::before {
      height: $ssp-no-results-sm-image-height;
    }
  }

  .view-ssp-events & {
    &::before {
      background-image: url('../images/no-results/events.svg');
    }
  }

  .view-ssp-organisations-members &,
  .view-ssp-committee-members &,
  .view-ssp-members-directory &,
  .view-ssp-public-members-directory &,
  .block-ssp-core-organisations--ssp-org-memberships & {
    &::before {
      background-image: url('../images/no-results/members.svg');
    }
  }

  .view-ssp-committees-listing &,
  .view-ssp-organisations-listing & {
    &::before {
      background-image: url('../images/no-results/committees.svg');
    }
  }

  .view-ssp-organisations-reports & {
    &::before {
      background-image: url('../images/no-results/reports.svg');
    }
  }

  .view-ssp-committee-meetings & {
    &::before {
      background-image: url('../images/no-results/meetings.svg');
    }
  }

  .view-ssp-committee-documents & {
    &::before {
      background-image: url('../images/no-results/documents.svg');
    }
  }

  .view-exhibitor-products & {
    &::before {
      background-image: url('../images/no-results/products.svg');
    }
  }

  .view-exhibitor-videos & {
    &::before {
      background-image: url('../images/no-results/videos.svg');
    }
  }

  .view-exhibitor-brands & {
    &::before {
      background-image: url('../images/no-results/brands.svg');
    }
  }

  .view-ssp-invoices & {
    &::before {
      background-image: url('../images/no-results/invoices.svg');
    }
  }

  .view-ssp-news & {
    &::before {
      background-image: url('../images/no-results/news.svg');
    }
  }

  .view-ssp-award-forms &,
  .view-ssp-awards-applications & {
    &::before {
      background-image: url('../images/no-results/awards.svg');
    }
  }

  .view-ssp-awards-submitted & {
    &::before {
      background-image: url('../images/no-results/submitted-applications.svg');
    }
  }

  .view-awards-review-applicants & {
    &::before {
      background-image: url('../images/no-results/applications.svg');
    }
  }

  &.ssp-no-results--with-query {
    &::before {
      background-image: url('../images/no-results/with-query.svg');
    }
  }

  &.ssp-thank-you-page {
    &::before {
      background-image: url('../images/signup-thank-you.svg');
    }
  }

  .view-ssp-certificates-cases &,
  .view-ssp-certificates-events &,
  .view-ssp-certificates-membership &,
  .view-ssp-certificates-cases-from-relationship &,
  .view-ssp-certificates-memberships-from-relationship &,
  .view-ssp-certificates-events-from-relationship &,
  .view-ssp-additional-certificates & {
    &::before {
      background-image: url('../images/no-results/certificates.svg');
    }
  }

  .view-ssp-cpd-activities & {
    margin-bottom: $ssp-cpd-no-results-margin-bottom;
    margin-top: 0;

    &::before {
      background-image: url('../images/no-results/cpd.svg');
      height: $ssp-cpd-no-results-height;
      width: $ssp-cpd-no-results-width-mobile;

      @media (min-width: 768px) {
        margin-left: $ssp-cpd-no-results-margin-left;
        margin-top: $ssp-cpd-no-results-margin-top;
        width: $ssp-cpd-no-results-width;
      }
    }
  }

  .view-ssp-case-forms &,
  .view-ssp-cases-listing & {
    &::before {
      background-image: url('../images/no-results/cases.svg');
    }
  }

  .view-ssp-my-tasks &,
  .view-ssp-my-organisation-tasks & {
    &::before {
      background-image: url('../images/no-results/tasks.svg');
    }
  }
}

.ssp-no-results__header {
  margin: $ssp-no-results-header-margin;

  .view-ssp-cpd-activities & {

    @include for-phone-only() {
      font-size: $ssp-headline-h6-font-size;
    }
    color: $ssp-cpd-no-results-header-color;
    margin-left: $ssp-cpd-no-results-header-margin-left;
  }
}

.ssp-no-results__content {
  margin: 0 auto;
  max-width: $ssp-no-results-content-max-width;
}

.ssp-no-results__button {
  margin: $ssp-no-results-button-margin;
  max-width: $ssp-no-results-content-max-width;
}
