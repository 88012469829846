/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Core bootstrap well styles overrides.
 */

.well {
  background-color: $ssp-brand-white;
  border: $wb-well-border;
  border-radius: $wb-well-border-radius;
  border-width: $ssp-bootstrap-well-border-width;
  box-shadow: $wb-well-shadow;
  margin-bottom: $ssp-bootstrap-well-dashboard-block-bottom-spacing;
  padding: $ssp-bootstrap-well-padding;

  h1 {
    border: $ssp-bootstrap-well-headline-border;
    line-height: $ssp-bootstrap-well-headline-line-height;
    margin: $ssp-bootstrap-well-headline-margin;
    text-transform: capitalize;
  }

  h3 {
    line-height: $ssp-bootstrap-well-title-line-height;
    margin: $ssp-bootstrap-well-title-margin;
  }

  blockquote {

    @include for-phone-only() {
      background-position: $ssp-bootstrap-quot-background-position-mobile;
      padding: $ssp-bootstrap-quot-padding-mobile;
    }
    background: $ssp-brand-primary url('../images/quot.svg') no-repeat;
    background-position: $ssp-bootstrap-quot-background-position;
    border: 0;
    border-radius: $ssp-border-radius-default;
    color: $ssp-brand-white;
    display: flex;
    flex-direction: column;
    font-weight: $ssp-font-weight-bold;
    justify-content: center;
    margin: $ssp-bootstrap-quot-margin;
    min-height: $ssp-bootstrap-quot-min-height;
    padding: $ssp-bootstrap-quot-padding;

    p:last-child {
      &::after {
        content: ' “';
      }
    }
  }

  .view-content > & {
    margin-bottom: $ssp-view-card-bottom-margin;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
