/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Typography styles overrides.
 */

// Blockquotes
.blockquote-footer::before {
  content: none;
}

blockquote {
  margin: revert;
}

.cw-block-quote__author {
  margin-top: $cw-block-quote_author-margin-top-xs;
}

.cw-block-quote__closing-quote {
  margin-top: $cw-block-quote_closing-quote-margin-top-xs;
}

@include media-breakpoint-up(sm) {
  .container--narrow .cw-block-quote__author {
    margin-top: $cw-block-quote_author-margin-top-sm;
    padding-left: $cw-block-quote_author-padding-left-sm;
  }
}

@include media-breakpoint-up(md) {
  .cw-block-quote__author {
    margin-top: $cw-block-quote_author-margin-top-sm;
    padding-left: $cw-block-quote_author-padding-left-sm;
  }

  .cw-block-quote__closing-quote {
    margin-top: 0;
  }
}
