/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Navbar styles overrides.
 */

.header__container {
  display: block;
}

.header__logo {
  width: 100%;
}

.header__menu {
  background-color: $body-bg;
  width: 100%;
}

.navbar-brand-image {
  max-height: $cw-header-logo-image-max-height-small-devices;
}

@include media-breakpoint-up(lg) {
  .header__container {
    display: flex;
  }

  .header__logo {
    width: $cw-header-logo-block-width;
  }

  .header__menu {
    width: $cw-header-menu-block-width;
  }

  .navbar-brand-image {
    max-height: $cw-header-logo-image-max-height;
  }
}

.navbar-nav .bi-search {
  margin-right: $cw-header-search-icon-margin-right;
}

.dropdown-toggle::after {
  content: none;
}
