/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

.eu-cookie-compliance-banner-info {
  margin: map-get($spacers, 2) auto !important;

  .eu-cookie-compliance-secondary-button {
    background-color: $success !important;
    border-color: $success !important;
    margin-right: ($spacer * 0.19) !important;
  }

  .eu-cookie-compliance-default-button {
    background-color: $secondary !important;
    background-image: unset !important;
    border-color: $secondary !important;
    box-shadow: none !important;
    text-shadow: 0 0 $black !important;
  }

  .eu-cookie-compliance-default-button,
  .eu-cookie-compliance-secondary-button {
    border-radius: $btn-border-radius !important;
    color: $white !important;
    font-family: var(--bs-body-font-family) !important;
    font-size: $font-size-base !important;
    font-weight: $btn-font-weight !important;
    line-height: var(--bs-body-line-height) !important;
    padding: $btn-padding-y $btn-padding-x !important;
  }

  h1 {
    font-size: $h1-font-size !important;
  }

  h2 {
    font-size: $h2-font-size !important;
  }

  h3 {
    font-size: $h3-font-size !important;
  }

  p {
    font-size: $font-size-base !important;
  }

  .popup-content.info {
    p {
      font-size: $font-size-base !important;
      font-weight: initial !important;
    }
  }

  #popup-buttons {
    margin: ($spacer * 0.15) 0 map-get($spacers, 2) 0 !important;
  }
}
