/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Tiny slider styles overrides.
 */

.tns-outer {
  position: relative;
}

.tns-ovh {
  margin: 0 $cw-tns-ovh-margin-x;
}

.tns-item {
  touch-action: pan-y;
  user-select: none;
}

//
// Slider arrow
//
.tns-controls [data-controls='prev'] {
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
  @extend .carousel-control-prev;
}

.tns-controls [data-controls='next'] {
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
  @extend .carousel-control-next;
}

.tns-controls [data-controls] {
  width: $cw-tns-controls-width;
}

.tns-controls {
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    filter: $carousel-dark-control-icon-filter;
  }
}
