/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Site footer layouting and styles.
 */

.ssp-footer {

  @include for-phone-only() {
    text-align: center;
  }
  background-color: $ssp-footer-background-color;
  color: $ssp-footer-text-color;
  margin-top: $ssp-footer-content-spacing;
  padding: $ssp-footer-padding;
  width: 100%;
}

.region-sspx-footer {

  @include for-tablet-portrait-up() {
    flex-direction: row;
  }
  align-items: center;
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 0;
  }
}

.ssp-footer__logo {

  @include for-tablet-portrait-up() {
    display: block;
    margin-right: $ssp-footer-logo-padding;
  }
  display: none;
  line-height: 0;

  img {
    height: $ssp-footer-logo-height;
  }
}

.ssp-footer-pipe-separator {
  color: $ssp-separator-color;
}

.ssp-address-footer {

  @include for-phone-only() {
    margin-bottom: $ssp-footer-address-bottom-spacing;
  }
  flex-grow: 1;
}
