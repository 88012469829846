/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Generic site form related styles.
 */

form {
  .form-actions {
    align-items: center;
    display: flex;

    .btn {
      margin: 0 0 0 auto;

      /* stylelint-disable selector-max-compound-selectors */
      + .btn {
        margin-left: $ssp-horizontal-spacing;
      }

      &.pull-left {
        margin-left: 0 !important;
        margin-right: $ssp-horizontal-spacing;

        + .btn { /* stylelint-disable-line max-nesting-depth */
          margin-left: auto;
        }
      }
      /* stylelint-enable */
    }
  }

  td,
  th {
    &.checkbox,
    &.radio {
      display: table-cell;
    }
  }

  // Better exposed filter date picker styles.
  .bef-datepicker {
    &[readonly] {
      // Using the same input color from bootstrap
      // to maintain consistency.
      background-color: $input-bg;
    }
  }

  .form-number {
    @extend .form-control; /* stylelint-disable-line scss/at-extend-no-missing-placeholder */
  }

  .input-group {
    .form-text {
      margin: 0;
    }
  }

  .form-type-date-popup {
    label {
      display: block;
    }
  }
}

.field-type-entityreference {
  .d-grid {
    button.btn {
      display: none;
    }
  }
}
