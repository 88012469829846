/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Helpers and utilities styles.
 */

.cw-text-truncate {
  @include cw-text-truncate();
}

.cw-position-absolute-md {
  position: relative;
}

@include media-breakpoint-up(md) {
  .cw-position-absolute-md {
    position: absolute;
  }
}

// For advert tower block sticky behaviour.
@media(min-width: 768px) {
  .advert-block-tower {
    position: sticky;
    top: 0;
  }
}
