/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Full card css component.
 * Custom reusable styles for events documents and news.
 */
.ssp-full-card {
  align-items: flex-start;
  display: flex;
  position: relative;
}

.ssp-full-card__calendar {
  background-color: $ssp-date-widget-background-color;
  border-radius: $ssp-date-widget-radius;
  display: flex;
  flex-basis: $ssp-date-widget-width;
  flex-direction: column;
  margin-right: $ssp-full-card-content-left-spacing;
  min-width: $ssp-date-widget-width;
  overflow: hidden;
  text-align: center;
}

.ssp-full-card__calendar-month {
  background-color: $ssp-date-widget-month-background-color;
  color: $ssp-date-widget-month-text-color;
  font-size: $ssp-date-widget-month-font-size;
  line-height: $ssp-date-widget-month-line-height;
  text-align: center;
  text-transform: uppercase;
}

.ssp-full-card__calendar-day {
  color: $ssp-date-widget-day-text-color;
  font-size: $ssp-date-widget-day-font-size;
  font-weight: $ssp-date-widget-day-font-weight;
  line-height: $ssp-date-widget-day-line-height;
}

.ssp-full-card__left-image {
  @include unwell('top-left-bottom');

  @include for-tablet-landscape-up() {
    .ssp-full-card--column & {
      @include unwell('top-left-right');

      margin-bottom: $ssp-bootstrap-well-padding;
    }
  }
  flex-shrink: 0;

  margin-right: $ssp-bootstrap-well-padding;
  overflow: hidden;

  .ssp-full-card--column-force & {
    @include unwell('top-left-right');

    margin-bottom: $ssp-bootstrap-well-padding;
    width: calc(100% + #{$ssp-bootstrap-well-padding * 2});

    img {
      height: auto;
      width: 100%;
    }
  }
}

.ssp-full-card__summary {
  @include for-tablet-landscape-up() {
    .ssp-full-card--column & {
      flex-grow: 1;
    }
  }
}

.ssp-full-card__content {

  @include for-tablet-landscape-up() {
    .ssp-full-card--column & {
      display: flex;
      flex-direction: column;
    }
  }
  align-self: normal;
  flex-grow: 1;

  h4 {

    @include for-tablet-landscape-up() {
      // Change font size of ssp-full-card content headline if it's inside col-md-6 and viewed in
      // desktop devices.
      .col-md-6 .ssp-full-card--column & { /* stylelint-disable-line selector-max-compound-selectors */
        font-size: $ssp-headline-h2-font-size;
      }
    }
    margin: 0 0 $ssp-full-card-content-line-bottom-spacing 0;
  }
}

.ssp-full-card__content-subtitle {
  color: $ssp-full-card-subtitle-text-color;
  margin-bottom: $ssp-full-card-subtitle-line-bottom-spacing;
}

.ssp-full-card__footer {

  @include for-tablet-landscape-up() {
    align-items: center;
    display: flex;

    .ssp-full-card__cta {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
    }

    .ssp-full-card__content-subtitle {
      margin: 0;
    }
  }
  margin: $ssp-full-card-footer-top-spacing 0 0 0;

  >.field-content {
    div:not(:last-child) {
      margin-bottom: $ssp-full-card-footer-links-spacing;
    }
  }
}
