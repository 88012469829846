/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Admin config pages css overrides.
 */
.page-admin {
  fieldset.panel {
    .panel-body {
      border: $ssp-my-organisations-border;
      margin-bottom: $ssp-button-icon-margin;
      padding: $ssp-horizontal-spacing;
    }
  }
}
