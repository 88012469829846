/* stylelint-disable max-nesting-depth, selector-max-compound-selectors */
/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Styling client webforms like Update Details.
 */

.webform-client-form {
  .panel-heading {
    .panel-title {
      font-size: inherit;
    }
  }

  .panel {
    margin: $ssp-webform-panel-margin;

    &.ssp-last-form-item-no-margin {
      .form-item:last-child {
        margin-bottom: 0;
      }
    }

    &:not(.collapsible) {
      .panel-body {
        .panel {
          &.collapsible {
            margin-left: $ssp-bootstrap-well-padding;
            margin-right: $ssp-bootstrap-well-padding;
            width: calc(100% - #{2 * $ssp-bootstrap-well-padding});
          }
        }
      }
    }

    &.collapsible {
      height: auto;
      margin-bottom: $ssp-bootstrap-well-padding;

      > .panel-heading {
        border: $ssp-collapsible-panel-border;
        border-bottom: 0;
        border-radius: $ssp-collapsible-panel-border-radius $ssp-collapsible-panel-border-radius 0 0;
        margin-bottom: 0;
        padding: $ssp-collapsible-panel-heading-padding;

        a {

          @include hover {
            color: inherit;
            text-decoration: none;
          }

          &::after {

            @include fa-icon('\f078');
            font-size: 0.8em;
            margin-left: 8px;
          }
        }
      }

      > .panel-body {
        border: $ssp-collapsible-panel-border;
        border-radius: 0 0 $ssp-collapsible-panel-border-radius $ssp-collapsible-panel-border-radius;
        border-top: 0;
        margin: 0;
      }
    }

    &.collapsed {
      .panel-heading {
        border-bottom: $ssp-collapsible-panel-border;
        border-radius: $ssp-collapsible-panel-border-radius;

        a::after {
          content: '\f054';
        }
      }
    }

    /* stylelint-disable no-duplicate-selectors */
    &.ssp-last-form-item-no-margin {
      margin-bottom: $ssp-bootstrap-well-padding;

      .form-item:last-child {
        margin-bottom: 0;
      }
    }
    /* stylelint-enable no-duplicate-selectors */

    .panel {
      .panel-heading {
        font-family: $ssp-internal-panel-heading-font-family;
        font-size: $ssp-internal-panel-heading-font-size;
        margin-bottom: $ssp-internal-panel-heading-bottom-margin;
        padding: 0 $ssp-bootstrap-well-padding;
      }

      &:not(.collapsible) {
        .panel-heading {
          margin-bottom: $ssp-internal-panel-heading-bottom-margin;
          padding: 0 $ssp-bootstrap-well-padding;
        }
      }
    }
  }

  .panel-body {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -#{$ssp-bootstrap-well-padding};

    &.collapse.in {
      display: flex;
    }

    .panel {
      margin-bottom: 0;
      width: 100%;

      &.collapsible {
        margin: $ssp-collapsible-panel-spacing;
        width: calc(100% - #{2 * $ssp-collapsible-panel-spacing});
      }
    }

    .panel-body {
      margin: 0;
    }

    > div {
      flex-basis: 100%;
      padding: 0 #{$ssp-bootstrap-well-padding};
      width: 100%;
    }

    /* stylelint-disable selector-no-qualifying-type */
    > .form-item:not(
    #{$ssp-webform-two-coloumn-layout-skip-selector}
  ),
    > div[id*='ajax-wrapper'] {

      @include for-tablet-portrait-up() {

        @include webform-full-width-fields {
          flex-basis: 100%;
        }
        flex-basis: calc(50%);

        &.ssp-webform-field-full-span {
          flex-basis: 100%;

          .form-control,
          .select2-container {
            // Add !important to override select2-container width.
            width: calc(50% - #{$ssp-bootstrap-well-padding}) !important;
          }
        }
      }

      .form-item {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .help-block {
      margin: 0 0 $ssp-bootstrap-well-padding 0;
    }
  }

  .ssp-webform-fieldset-with-full-span-fields {
    > .panel-body {
      flex-direction: column;

      > .form-item {
        @include for-tablet-portrait-up() {

          @include webform-full-width-fields {
            width: 100%;
          }
          width: 50%;
        }
      }
    }
  }

  .form-item {
    &.ssp-webform-field-full-width {
      flex-basis: 100% !important;
      width: 100% !important;

      .checkbox,
      .radio {
        width: 100% !important;
      }
    }

    &.ssp-webform-field-full-span {
      flex-basis: 100% !important;

      .form-control,
      .select2-container {
        // Add !important to override select2-container width.
        width: calc(50% - #{$wb-layout-box-field-spacing-x}) !important;
      }
    }
  }

  .webform-component-checkboxes,
  .webform-component-radios {
    .form-item {
      .control-label {
        font-size: inherit;

        span.form-element-label {
          display: block;
        }
      }
    }

    .help-block {
      display: block;
      // Add '!important' to avoid duplicating this style to
      // .panel-body .help-block selector (see below in this file)
      margin-top: $ssp-form-help-text-spacing !important;
    }
  }

  .webform-component-date {
    &.form-item {
      width: 100%;
    }

    .form-item {

      @include for-phone-only() {
        &:nth-child(1) {
          margin-bottom: $ssp-inline-datepicker-item-spacing;
        }
      }
      display: inline-block;
      margin: 0 $ssp-inline-datepicker-item-spacing 0 0;

      &[class*='-day'] {
        width: $ssp-inline-datepicker-item-width-day;
      }

      &[class*='-month'] {
        width: $ssp-inline-datepicker-item-width-month;
      }

      &[class*='-year'] {
        width: $ssp-inline-datepicker-item-width-year;
      }
    }
  }

  .webform-component-time {
    .form-item {
      display: inline-block;
      margin: 0;
      width: $ssp-inline-timepicker-item-width;

      &:nth-child(1) {
        margin-right: $ssp-inline-timepicker-item-spacing;
      }

      &:nth-child(2) {
        margin-left: $ssp-inline-timepicker-item-spacing;
      }
    }

    .form-radios {
      margin-left: $wb-layout-box-field-spacing-x;
      white-space: nowrap;

      .form-item {
        width: auto;

        label {
          margin-right: 0;
        }

        &:not(:last-child) {
          label {
            margin-right: $ssp-inline-timepicker-item-spacing;
          }
        }
      }
    }
  }

  .readonly {
    background-color: $pre-bg;
    pointer-events: none;
  }
}
