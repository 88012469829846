/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/* stylelint-disable selector-no-qualifying-type, selector-max-compound-selectors*/

.crm-container {
  // General CRM section styles.
  .crm-section {
    margin-bottom: $padding-large-vertical;

    > .label {
      color: unset;
      display: block;
      font-size: inherit;
      line-height: $line-height-base;
      padding: 0;
      text-align: left;
      white-space: initial;
    }

    &.crm-public-form-item,
    &.form-item {
      margin-bottom: $form-group-margin-bottom;
    }
  }

  .form-group {
    margin-bottom: $form-group-margin-bottom;
  }

  .crm-section,
  .crm-accordion-wrapper {
    .description {
      color: $wb-civicrm-description-text-color;
      font-size: $wb-civicrm-description-text-size;
      margin-top: $padding-small-vertical;
    }
  }

  // Copy styles of twbs .form-control.
  .crm-form-textarea,
  .crm-form-text {
    @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.075));
    @include transition(border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s);

    // Customize the `:focus` state to imitate native WebKit styles.
    @include form-control-focus;
    
    background-color: $input-bg;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid $input-border;
    border-radius: $input-border-radius; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.
    color: $input-color;
    display: block;
    font-size: $font-size-base;
    height: $input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
    line-height: $line-height-base;
    padding: $wb-input-padding-y $wb-input-padding-x;
    width: 100%;

    // Unstyle the caret on `<select>`s in IE10+.
    &::-ms-expand {
      background-color: transparent;
      border: 0;
    }

    // Disabled and read-only inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
    &[disabled],
    &[readonly],
    fieldset[disabled] & {
      background-color: $input-bg-disabled;
      opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
    }

    &[disabled],
    fieldset[disabled] & {
      cursor: $cursor-disabled;
    }
  }

  .crm-form-textarea {
    height: auto;
    resize: none;
  }

  .crm-submit-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: $form-group-margin-bottom;

    .crm-form-submit:only-child {
      margin-left: auto;
    }
  }

  .crm-button {
    @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $btn-border-radius-base);

    border: 1px solid transparent;
    font-weight: $btn-font-weight;

    &.default {
      @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
    }

    &.cancel {
      @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
    }

    &.crm-button-type-back {
      order: -1;
    }
  }

  // Copying styles of h3
  .header-dark {
    color: $headings-color;
    font-family: $headings-font-family;
    font-size: $font-size-h3;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    margin-bottom: ($line-height-computed / 2);
    margin-top: $line-height-computed;
  }

  .crm-block {
    // Copy styles of alert info block.
    .help,
    .status {
      @include alert-block();
      @include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);
    }
  }

  .messages {
    &.error,
    &.crm-error {
      @include alert-block();
      @include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
    }
  }

  .crm-accordion-wrapper {
    margin-bottom: $form-group-margin-bottom;

    .crm-accordion-header {
      color: $btn-primary-color;
      cursor: pointer;
      display: inline-block;

      &::after {
        @include icon('\F286');
      }
    }

    &.collapsed {
      .crm-accordion-header {
        /* stylelint-disable max-nesting-depth */
        &::after {
          @include icon('\F282');
        }
        /* stylelint-enable max-nesting-depth */
      }
    }

    .crm-accordion-body {
      background-color: $wb-accordion-body-background-color;
      border: $wb-accordion-body-border;
      border-radius: $wb-accordion-body-border-radius;
      padding: $wb-accordion-body-padding;
    }
  }

  table.form-layout-compressed,
  table.form-layout {
    background-color: inherit;
  }

  // Price set
  .price-set-row {
    @include multi-options-container('.price-set-option-content', 'label[for]', 'input');
    @include multi-options-container-radio('.price-set-option-content', 'label[for]', 'input');
    clear: both;

    > label[for] {
      margin: unset;
    }
  }

  // Payment processor section
  .payment_processor-section {
    @include multi-options-container('> .content', 'label[for]', 'input');
    @include multi-options-container-radio('> .content', 'label[for]', 'input');

    margin-bottom: $form-group-margin-bottom;

    .content {
      @include clearfix();

      label[for] {
        clear: left;
        float: left;
        margin-bottom: $wb-multi-option-spacing-bottom;

        /* stylelint-disable max-nesting-depth */
        ~ label[for] {
          margin-top: $wb-multi-option-option-top-adjustment;
        }
        /* stylelint-enable max-nesting-depth */
      }

      .crm-error {
        @include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);

        clear: left;
        float: left;
      }
    }
  }

  // Payment icons
  .crm-credit_card_type-icons {
    $payments: 'amex', 'discover', 'mastercard', 'visa';
    display: flex;
    margin-bottom: $form-group-margin-bottom;

    > a {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      height: $wb-civicrm-billing-card-icon-height;
      margin-right: $wb-civicrm-billing-card-icon-right-margin;
      width: $wb-civicrm-billing-card-icon-width;

      span {
        display: none;
      }
    }

    @each $payment in $payments {
      .crm-credit_card_type-icon-#{$payment} {
        background-image: url('../images/payments/#{$payment}.png');
      }
    }
  }

  // Card details section
  .billing_name_address-section,
  .credit_card_info-section {
    > .crm-section {
      margin-bottom: $form-group-margin-bottom;
    }

    .crm-form-select {
      height: $wb-select2-input-height;
      width: 100%;
    }

    .crm-select2 {
      width: 100% !important;
    }

    // To fixate the alert error styles (with label) for inconsistent
    // CiviCRM error HTML.
    .content {
      .crm-error {
        @include wb-label();
        @include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);

        background: none;
        display: inline-block;
      }
    }
  }

  .billing_name_address-section {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -#{$wb-layout-box-field-spacing-x / 2};

    .billing_first_name-section,
    .billing_middle_name-section,
    .billing_last_name-section {
      flex-basis: 33.33333%;
    }

    .crm-section {
      padding: 0 $wb-layout-box-field-spacing-x / 2;

      &[class*='billing_street_address'] {
        flex-basis: 100%;
      }

      &[class*='billing_country_'],
      &[class*='billing_city'],
      &[class*='billing_state_province'],
      &[class*='billing_postal_code'] {
        flex-basis: 50%;
      }
    }

    select[class*='crm-form-select'] {
      width: 100%;
    }
  }

  .billing_mode-section {
    display: flex;
    flex-wrap: wrap;

    .credit_card_type-section,
    .credit_card_number-section {
      flex-basis: 100%;
    }

    .credit_card_exp_date-section,
    .cvv2-section {
      flex-basis: 50%;
    }

    .cvv2-section {
      order: 1;
      padding-left: $wb-layout-box-field-spacing-x / 2;
    }

    .credit_card_exp_date-section {
      padding-right: $wb-layout-box-field-spacing-x / 2;
    }
  }

  .credit_card_exp_date-section {
    width: 50%;

    .crm-form-select {
      @include form-control-focus;

      background-color: $input-bg;
      border-color: $input-border;
      border-radius: $input-border-radius;
      color: $input-color;
      padding: $padding-base-vertical $padding-base-horizontal;
    }

    .content {
      display: grid;
      font-size: 0; // Hack to hide the &nbsp; on the page.
      grid-gap: 0 $wb-layout-box-field-spacing-x;
      grid-template:
        'a b' auto
        'c d' auto/
        1fr 1fr;

      [name='credit_card_exp_date[M]'] {
        font-size: $font-size-base; // Resetting the original font size.
        grid-area: a;
      }

      [name='credit_card_exp_date[Y]'] {
        font-size: $font-size-base; // Resetting the original font size.
        grid-area: b;
      }

      .alert-danger[for='credit_card_exp_date_M'] {
        font-size: $wb-label-font-size; // Resetting the original font size.
        grid-area: c;
      }

      .alert-danger[for='credit_card_exp_date_Y'] {
        font-size: $wb-label-font-size; // Resetting the original font size.
        grid-area: d;
      }
    }
  }

  .cvv2-section {
    .content {
      padding-right: $wb-civicrm-billing-card-cvv-right-padding;
      position: relative;

      &::after {
        @include fa-icon('\f09d', $wb-civicrm-billing-card-cvv-image-font-size);

        position: absolute;
        right: $wb-civicrm-billing-card-cvv-image-right-spacing;
        top: $input-height-base / 2;
        transform: translate(0%, -50%);
      }
    }
  }
}
