/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Donate block related styles - used on dashboard
 */
.ssp-donate-block {

  @include for-tablet-portrait-up() {
    // Need to add extra margin here, because .ssp-donate__image has respective
    // negative margin.
    margin-top: $ssp-bootstrap-well-dashboard-block-bottom-spacing + $ssp-bootstrap-well-padding;
  }
  margin-top: $ssp-bootstrap-well-dashboard-block-bottom-spacing;
}

.ssp-donate {
  @include for-tablet-portrait-up() {
    display: flex;
  }
}

.ssp-donate__image {

  @include for-tablet-portrait-up() {
    margin-right: $ssp-donate-image-right-spacing;
    margin-top: -#{$ssp-bootstrap-well-padding * 2};
  }

  @include for-phone-only() {
    margin-bottom: $ssp-bootstrap-well-dashboard-block-bottom-spacing;
  }
  flex-basis: $ssp-donate-image-width;
}
