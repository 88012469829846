/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

.webform-datepicker {
  align-items: center;
  display: flex;

  .form-item {
    margin: 0 $wb-webform-datepicker-item-spacing 0 0;
  }

  .webform-calendar {
    border: 0;
    opacity: 0;
    padding: 0;
    position: relative;
    width: $wb-webform-datepicker-calendar-icon-font-size;
    z-index: 1;
  }

  &::after {
    @include icon('\F201');
    color: $wb-webform-datepicker-calendar-icon-color;
    font-size: $wb-webform-datepicker-calendar-icon-font-size;
    transform: translateX(-100%);
  }
}
